@font-face {
  font-family: optician;
  src: url("../assets/fonts/Optician-Sans.otf");
}

body {
  margin: 0;
  font-family: "optician", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  overflow-x: hidden;
}

html {
  overflow-x: hidden;
}

.container {
  position: absolute;
  width: 300px;
  height: 300px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  .loader {
    width: 115px;
    height: 120px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;

    .fa {
      transform-origin: 50% 50%;
      transform: scale(0.95);
    }

    .icon {
      -webkit-font-smoothing: antialiased;
      color: white;
    }

    .icon {
      &:nth-child(3),
      &:nth-child(4) {
        margin-top: -9px;
      }
    }
  }
}

.background-landscape {
  background: url(../assets/images/landscape_resized.png) no-repeat bottom
    center fixed;
  background-size: cover;
  min-height: 100vh;
  color: rgb(255, 230, 0);
  h1 {
    text-shadow: #000 3px 3px 10px;
  }

  .social-icon {
    color: rgb(255, 230, 0);
    background: #0000008f;
    width: 30px;
    height: 30px;
    padding: 8px;
    border-radius: 500px;
  }
  /* overflow: hidden; */
}

.background-portrait {
  background: url(../assets/images/mobile_resized.png) no-repeat center center
    fixed;
  background-size: cover;
  min-height: 100vh;
  color: black;

  .social-icon {
    color: black;
  }
  /* overflow: hidden; */
}

h1 {
  font-size: 1.6em;
  text-align: center;
}

.main-link {
  color: black;
  text-decoration: none;
  background: #f3ff7a;
  padding: 0.25rem 1rem;

  margin: 0.5rem;
  text-align: center;
  margin: 0 3rem;
  /* border: 3px solid #000; */
}

h3 {
  font-size: 30px;
  margin-bottom: 0;
}

.main-link:hover,
.main-link:focus,
.main-link:active {
  color: #eeeeee;
  background: #000;
}

h2 {
  color: black;
  text-decoration: none;
  background: #eeeeee;
  padding: 0 2rem;
  margin: 0.5rem;
  text-align: center;
  margin: 1rem 3rem;
  font-weight: bold;
  box-shadow: 3px 5px 2px #0c0a0a;
}

.past-gig {
  color: #eeeeee;
  background: #000;
  padding: 0 1rem;

  margin: 0.5rem;
  text-align: center;
  margin: 0 3rem;
}

hr {
  margin: 1rem 0;
  /* color: rgb(68, 68, 68); */
  /* background-color: rgb(68, 68, 68); */
  border-bottom: 0.1px solid rgb(68, 68, 68);
  width: 20%;
  opacity: 3.25;
}

.video-container {
  overflow: hidden;
  position: relative;
  width: 75%;
  max-width: 636px;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 600px) {
  h1 {
    font-size: 2em;
  }

  /* 
  .video {
      width: 560px;
      height: 315px;
  } */
}

.artwork {
  box-shadow: #000000 5px 7px 20px;
}

.EPK__box {
  padding: 2rem;
}

.EPK__container {
  background-color: rgb(255 255 255 / 80%);
  overflow-wrap: break-word;
  box-shadow: 3px 3px 10px #0c0c0c;
  padding: 1rem;
  text-align: center;
  width: 80%;

  h2 {
    color: black;
    background: none;
    margin: 0 0 1rem 0;
    box-shadow: none;
  }
}

.login {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.login__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.login__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.EPK__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: black;
  text-decoration: none;
  background: #f3ff7a;
}

.EPK__btn:hover {
  color: #f3ff7a;
  background: black;
}

.login__google {
  background-color: #4285f4;
}

.login div {
  margin-top: 7px;
}

.dashboard {
  // height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.dashboard__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.dashboard__btn {
  padding: 10px;
  font-size: 18px;
  margin-top: 10px;
  border: none;
  color: white;
  background-color: black;
}

.dashboard div {
  margin-top: 7px;
}

.reset {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset__container {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #dcdcdc;
  padding: 30px;
}

.reset__textBox {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
}

.reset__btn {
  padding: 10px;
  font-size: 18px;
  margin-bottom: 10px;
  border: none;
  color: white;
  background-color: black;
}

.va-center {
  vertical-align: middle;
}

.form-control {
  width: 300px;
}

// @import './ps';

.external-link {
  color: black;
  text-decoration: none;
  // background: #eeeeee;
  font-size: 1.5rem;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 0 0.5rem;
  
  text-align: center;
  margin: 1rem 3rem;
  @media screen and (max-width: 600px) {
    margin: 0.65rem;
    font-size: 1.25rem;
  }

  font-weight: bold;

  &.right {
    text-align: cetner;
  }
}

.external-link:hover, .external-link:active {
  color: white;
  background-color: rgba(0, 0, 0, 0.4);
}
